import React, { useEffect } from 'react';
import get from 'lodash.get';
import { useUser } from '../utils/user';

import { useQuery } from '../utils/hooks';
import { performFetch } from '../sagas';

const TrainingPermissionsQuery = ({
    children,
    data = {},
    classroomId,
    ...rest
}) => {
    const {
        fetchDispatch: trainingPermissionsFetchDispatch,
        state: trainingPermissionsState,
    } = useQuery('getTrainingPermissions');

    const activeTrainings = new Set(
        get(trainingPermissionsState, 'data.trainings', [])
    );

    const { idToken } = useUser();

    const callGetTrainingPermissions = () => {
        performFetch(trainingPermissionsFetchDispatch, {
            params: {
                path: `/${encodeURIComponent(classroomId)}`,
            },
            api: trainingPermissionsState,
            idToken,
        });
    };

    useEffect(() => {
        if (activeTrainings.size === 0 && classroomId && idToken) {
            callGetTrainingPermissions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classroomId, idToken]);

    const forwardedProps = {
        ...rest,
        classroomId,
        data: {
            ...data,
            activeTrainings,
        },
    };

    if (children.props) {
        return React.cloneElement(children, forwardedProps);
    } else {
        return children(forwardedProps);
    }
};

export default TrainingPermissionsQuery;
