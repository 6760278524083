import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import Loader from '../Loader';
import messages from './ClassPage.messages';
import ClassBanner from '../ClassBanner';
import ClassMain from '../ClassMain';
import { useLangLocale } from '../LangLocaleProvider';

import './ClassPage.scss';

import { useHistory } from 'react-router-dom';
import { useUserManager } from '../.config/configureAuth';
import { ActiveTrainingsProvider } from '../ActiveTrainingsProvider';
import { useApiClient } from '../utils/hooks/useApiClient';
import { commonBreadcrumbs } from '../utils/breadcrumbs';
import ClassErrorContainer from '../ClassErrorContainer/ClassErrorContainer';
import {
    NOT_AUTHORIZED_TYPE,
    NOT_AUTHORIZED_CAUSE,
    MISSING_PII_CAUSE,
    UNVERIFIED_EMAIL_CAUSE,
} from '../utils';
import UserKeyDisplay from '../UserKeyDisplay/UserKeyDisplay';
import { DEFAULT_RETRIES } from '../QueryClientProviderWrapper/QueryClientProviderWrapper';
import { Flashbar } from '@amzn/awsui-components-react';

const ClassPage = ({ intl: { formatMessage }, classroomId }) => {
    const message = formatMessage(messages.classPageLongLoad);
    const { localeSet } = useLangLocale();
    const history = useHistory();
    const userManager = useUserManager();
    const location = useLocation();
    const bffClient = useApiClient();
    const [enrolling, enrollingSet] = useState(false);
    const [flashBarItem, setFlashBarItem] = useState([]);

    const getClassFetch = ({ queryKey: [, classId] }) =>
        bffClient.getClassroom(classId);

    const catchedCommonHandledErrors = err => {
        if (err.status === 401 && err.type === NOT_AUTHORIZED_TYPE) {
            userManager.signinRedirect({
                state: {
                    pathname: location.pathname,
                    search: location.search,
                },
            });
            return true;
        } else if (err.cause === UNVERIFIED_EMAIL_CAUSE) {
            userManager
                .getUser()
                .then(user => {
                    return Promise.resolve(
                        user?.profile?.public_provider_name || 'COGNITO'
                    );
                })
                .then(idpName => {
                    userManager.signinRedirect({
                        extraQueryParams: {
                            require_email_verification: true,
                            identity_provider: idpName,
                        },
                        state: {
                            pathname: location.pathname,
                            search: location.search,
                        },
                    });
                });
            return true;
        } else if (err.cause === MISSING_PII_CAUSE) {
            history.push('/profile');
            return true;
        }
        return false;
    };

    const isNotEnrolledError = err =>
        err?.status === 403 && err.cause === NOT_AUTHORIZED_CAUSE;

    const { data, isLoading: getClassroomLoading, error, isSuccess } = useQuery(
        ['getClassroom', decodeURIComponent(classroomId)],
        getClassFetch,
        {
            enabled: !!bffClient.hasLoadedToken && !enrolling,
            retry: (attempt, error) => {
                if (isNotEnrolledError(error) || error?.status === 500) {
                    return false;
                }
                return (
                    attempt < DEFAULT_RETRIES &&
                    !catchedCommonHandledErrors(error)
                );
            },
            onError: error => {
                if (isNotEnrolledError(error)) {
                    enrollingSet(true);
                }
            },
        }
    );

    const locationData = data?.classroom?.locationData;

    const { isLoading: enrollStudentLoading, error: enrollingError } = useQuery(
        ['enrollStudent', decodeURIComponent(classroomId)],
        ({ queryKey: [, classId] }) => bffClient.enrollStudent(classId),
        {
            enabled: enrolling,
            onSuccess: () => enrollingSet(false),
            onError: catchedCommonHandledErrors,
        }
    );

    const title = _get(data, 'course.title');

    // We want to show the student guide button in the banner if
    // a guide exist and the classroom contains no labs
    const hasLabs = !!data?.content && !!Object.keys(data?.content).length;
    const showStudentGuideInBanner =
        !hasLabs && data?.course?.books?.studentGuide?.vbId;

    const breadcrumbItems = commonBreadcrumbs(formatMessage).concat([
        { text: title },
    ]);

    const isLoading = getClassroomLoading || enrollStudentLoading;

    const messageAfterSecondAndAHalf = error => {
        return isLoading && message;
    };
    const coalescedError = enrollingError || error;

    useEffect(() => {
        const classLangLocale = _get(data, 'classroom.langLocale');
        if (classLangLocale) {
            localeSet(classLangLocale);
        }
    }, [data, localeSet]);

    return (
        <>
            <Loader
                isLoading={isLoading}
                message={messageAfterSecondAndAHalf(error)}
                hasError={!isLoading && !!coalescedError}
                fallback={() => <ClassErrorContainer error={coalescedError} />}
            >
                <Flashbar items={flashBarItem} />
                <ClassBanner
                    title={title}
                    breadcrumbItems={breadcrumbItems}
                    locationData={locationData}
                    classroomId={classroomId}
                    setFlashBar={setFlashBarItem}
                    showStudentGuide={showStudentGuideInBanner}
                />
                <ActiveTrainingsProvider
                    classroomId={classroomId}
                    enabled={isSuccess && !enrolling}
                >
                    <ClassMain classData={data} />
                </ActiveTrainingsProvider>
            </Loader>
            <UserKeyDisplay />
        </>
    );
};

export default ClassPage;
