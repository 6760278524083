import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Modal, Button, Box } from '@amzn/awsui-components-react';

import { useQuery } from '../utils';
import { performFetch } from '../sagas';
import messages from './GuideButton.messages';
import { useUser } from '../utils/user';
import './GuideButton.scss';

const GuideButton = ({
    intl: { formatMessage },
    guideType,
    btnText,
    classId: decodedClassId,
    disabled,
}) => {
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ltiParams, setLtiParams] = useState({});
    const { fetchDispatch, state: studentGuideState } = useQuery('getGuides');
    const {
        data: studentGuideData,
        loading: studentGuideLoading,
        error: studentGuideError,
    } = studentGuideState;

    const encodedClassId = encodeURIComponent(decodedClassId);

    const formRef = useRef();
    const { idToken } = useUser();
    const guideDataExists = data => data && Object.keys(data).length;

    const onClickGuideButton = async (ev, type) => {
        ev.preventDefault();
        performFetch(fetchDispatch, {
            params: {
                method: 'GET',
                path: `/${encodedClassId}`,
            },
            api: studentGuideState,
            idToken,
        });
    };

    useEffect(() => {
        if (studentGuideError) {
            setErrorModalOpen(true);
        } else if (guideDataExists(studentGuideData)) {
            const params = _get(studentGuideData, guideType, {});
            setLtiParams(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentGuideData, studentGuideError]);

    useEffect(() => {
        if (Object.keys(ltiParams).length) {
            formRef.current.submit();
        }
    }, [ltiParams]);

    return (
        <Box>
            <Button
                iconAlign="right"
                iconName="external"
                data-test={`${guideType}-guide-btn`}
                ariaLabel={formatMessage(messages.guideButtonAriaLabel, {
                    buttonLabel: formatMessage(btnText),
                })}
                href="#"
                onClick={ev => onClickGuideButton(ev, guideType)}
                disabled={studentGuideLoading || disabled}
            >
                {formatMessage(btnText)}
            </Button>
            <form
                action="https://bc.vitalsource.com/books/book"
                method="POST"
                target="_blank"
                className="student-guide-form"
                rel="noopener noreferrer"
                ref={formRef}
            >
                {ltiParams &&
                    Object.keys(ltiParams).map(v => (
                        <input
                            key={v}
                            name={v}
                            value={ltiParams[v]}
                            type="hidden"
                        />
                    ))}
            </form>
            <Modal
                data-testid="guide-btn-modal"
                visible={errorModalOpen}
                header={formatMessage(messages.studentGuideErrorTitle)}
                closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
                onDismiss={() => setErrorModalOpen(false)}
                footer={
                    <Button
                        variant="primary"
                        onClick={() => setErrorModalOpen(false)}
                    >
                        {formatMessage(
                            messages.studentGuideErrorModalActionButton
                        )}
                    </Button>
                }
            >
                {formatMessage(messages.studentGuideErrorContent)}
            </Modal>
        </Box>
    );
};

GuideButton.propTypes = {
    intl: PropTypes.object,
    guideType: PropTypes.string.isRequired,
    btnText: PropTypes.object,
    classId: PropTypes.string,
    disabled: PropTypes.bool,
};

export default injectIntl(GuideButton);
