import React, { useState, useEffect, Fragment, useRef } from 'react';
import get from 'lodash.get';
import moment from 'moment-timezone';
import { Modal, Button } from '@amzn/awsui-components-react';

import Loader from '../Loader';
import messages from './StartTrainingQuery.messages';

import {
    logger,
    useQuery,
    generateIdempotencyToken,
    poll,
    openTab,
} from '../utils';
import { performFetch } from '../sagas';

import { clearData } from '../actions';
import { useUser } from '../utils/user';
import { createLabRedirectUrl } from '../utils/urls';
import { ActiveTrainingLink } from './ActiveTrainingLink';
import { convertFulillmentErrorToModal } from './StartTrainingQuery.utils';
import { useActiveTrainings } from '../ActiveTrainingsProvider';

const btnGenerator = (text, onClick, variant = 'primary') => ({
    text,
    onClick,
    variant,
});

const sanitizeUrlAndOpenTab = props => {
    const { labUrl, record } = createLabRedirectUrl(props);
    record();
    openTab(labUrl);
};

const StartTrainingQuery = ({
    children,
    queries = {},
    data = {},
    contentData = {},
    titleRegex,
    globals = window,
    ...rest
}) => {
    const { classroomId, langLocale, formatMessage, endsOn } = rest;
    const {
        trainings: loadedLabs,
        refetch: refetchLoadedLabs,
    } = useActiveTrainings();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalKey, setModalKey] = useState('generic');
    const [timeoutId, setTimeoutId] = useState(0);
    const contentIdRef = useRef('');

    const {
        fetchDispatch: createFetchDispatch,
        state: createTrainingState,
    } = useQuery('createTraining');

    const {
        fetchDispatch: getFetchDispatch,
        state: getTrainingState,
    } = useQuery('getTraining');

    const {
        data: createTrainingData,
        loading: createTrainingLoading,
        error: createTrainingError,
    } = createTrainingState;
    const createTrainingStatus = get(createTrainingData, 'fulfillmentStatus');

    const {
        data: getTrainingData,
        loading: getTrainingLoading,
    } = getTrainingState;
    const getTrainingStatus = get(getTrainingData, 'fulfillmentStatus');

    const {
        loading: combinedLoading,
        fulfillmentStatus: combinedFulfillmentStatus,
    } = Object.assign({}, createTrainingData, getTrainingData, {
        loading: createTrainingLoading || getTrainingLoading,
    });

    const modalContent = {
        timeout: {
            title: messages.pollTimeoutTitle,
            content: messages.pollTimeoutContent,
            btns: [btnGenerator(messages.modalClose, hideModal)],
        },
        inactive: {
            title: messages.inactiveTitle,
            content: messages.inactiveContent,
            btns: [btnGenerator(messages.modalConfirm, hideModal)],
        },
        classEnded: {
            title: messages.inactiveTitle,
            content: messages.classEndedContent,
            btns: [btnGenerator(messages.modalConfirm, hideModal)],
        },
        generic: {
            title: messages.genericTitle,
            content: messages.genericContent,
            btns: [btnGenerator(messages.modalClose, hideModal)],
        },
        tooMany: {
            title: messages.tooManyLabsTitle, // "Too many open labs"
            content: messages.tooManyLabsContent, // "You have reached te maxium of three open labs. Please close one of the following open labs before launching a new lab.",
            btns: [btnGenerator(messages.modalClose, hideModal)],
        },
    }[modalKey];

    const loading = combinedFulfillmentStatus === 'pending' || combinedLoading;

    function hideModal() {
        setModalVisible(false);
    }

    const { idToken } = useUser();

    const callStartTraining = async arn => {
        contentIdRef.current = arn;
        createFetchDispatch(clearData());
        getFetchDispatch(clearData());
        performFetch(createFetchDispatch, {
            params: {
                method: 'POST',
                body: {
                    arn,
                    classroomId,
                    idempotencyToken: generateIdempotencyToken(),
                    metaData: {
                        preferredLangLocale: langLocale,
                    },
                },
            },
            api: createTrainingState,
            idToken,
        });
    };

    const getTraining = async () => {
        const encodedClassroomId = encodeURIComponent(classroomId);
        performFetch(getFetchDispatch, {
            params: {
                path: `/${encodedClassroomId}/${createTrainingData.trainingId}`,
            },
            api: getTrainingState,
            idToken,
        });
    };

    const handleError = error => {
        const key =
            {
                'Poll timeout': 'timeout',
                'Inactive training': 'inactive',
                'Class Ended': 'classEnded',
            }[error] || 'generic';

        setModalKey(key);
        setModalVisible(true);
    };

    const commonOpenTabProps = {
        classroomId,
        sessionId: globals.sessionStorage.getItem(
            'aws.beaker.clientLoggingUUID'
        ),
    };

    const handleFulfillmentError = error => {
        const { name, labUrl, activeLabsWithDetails } = error;
        if (name === 'ActiveLabsExist') {
            sanitizeUrlAndOpenTab({
                ...commonOpenTabProps,
                labUrl: activeLabsWithDetails?.[0].statlerUrl ?? labUrl, // there should only be one in this scenario
                langLocale,
                contentId: contentIdRef.current,
            });
        } else if (name === 'TooManyActiveLabs') {
            setModalKey('tooMany');
            setModalVisible(true);
        } else {
            logger.log(`Unhandled trainingSession error: ${name}`);
            setModalKey('generic');
            setModalVisible(true);
        }
    };

    const handleFulfillmentStatuses = statusData => {
        if (statusData.fulfillmentStatus === 'complete') {
            sanitizeUrlAndOpenTab({
                ...commonOpenTabProps,
                labUrl: statusData.metaData.labUrl,
                langLocale,
                contentId: contentIdRef.current,
            });
        } else {
            handleFulfillmentError(statusData.fulfillmentError);
        }
    };

    useEffect(() => {
        if (!createTrainingStatus) return;
        if (createTrainingStatus !== 'pending') {
            handleFulfillmentStatuses(createTrainingData);
            return;
        }

        const { start } = poll(getTraining, {
            interval: 1000,
            onError: handleError,
            onTimeoutIdChange: setTimeoutId,
        });
        start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createTrainingStatus]);

    useEffect(() => {
        // retry until fulfillmentStatus isn't pending
        if (!getTrainingStatus || getTrainingStatus === 'pending') return;
        refetchLoadedLabs();

        window.clearTimeout(timeoutId);

        handleFulfillmentStatuses(getTrainingData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTrainingStatus]);

    useEffect(() => {
        if (createTrainingError) {
            let msg = '';
            if (createTrainingError.status === 403) {
                if (moment().isAfter(moment.unix(endsOn))) {
                    msg = 'Class Ended';
                } else {
                    msg = 'Inactive training';
                }
            }

            handleError(msg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createTrainingError]);

    const forwardedProps = {
        ...rest,
        queries: {
            ...queries,
            callStartTraining,
        },
        data,
    };

    const labsDataForModal = convertFulillmentErrorToModal({
        getFulfillmentError: getTrainingData?.fulfillmentError,
        createFulfillmentError: createTrainingData?.fulfillmentError,
        loadedLabs,
        contentData,
        titleRegex,
        langLocale,
    });

    return (
        <Fragment>
            <Loader isLoading={loading}>
                {children.props
                    ? React.cloneElement(children, forwardedProps)
                    : children(forwardedProps)}
            </Loader>
            <Modal
                visible={modalVisible}
                data-test="start-training-query-modal"
                header={formatMessage(modalContent.title)}
                closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
                onDismiss={hideModal}
                footer={
                    <span className="awsui-util-f-r">
                        {modalContent.btns.map(({ text, ...modalRest }) => (
                            <Button key={text.id} size="small" {...modalRest}>
                                {formatMessage(text)}
                            </Button>
                        ))}
                    </span>
                }
            >
                {formatMessage(modalContent.content)}
                {labsDataForModal.map((props, i) => (
                    <ActiveTrainingLink {...props} key={i} />
                ))}
            </Modal>
        </Fragment>
    );
};

export default StartTrainingQuery;
